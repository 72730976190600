import React from 'react';
import { Router } from '@reach/router';

import { App } from 'modules/app';
import { LessonPage } from 'modules/lab';
import { MainNavigation } from 'modules/navigation';

export default class Lesson extends React.Component {
  render() {
    return (
      <App>
        <MainNavigation />
        <Router>
          <LessonPage path="/lesson/:lessonId" />
        </Router>
      </App>
    );
  }
}
